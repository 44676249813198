@import 'grid-thumbnail-variables';

.catalog-thumbnail-grid {
    display: grid;
    grid-template-columns: $catalog-grid-thumbnail__template-columns;
    grid-gap: $catalog-grid-thumbnail__gap;
    margin: $catalog-grid-thumbnail__margin;
    padding: $catalog-grid-thumbnail__padding;
    list-style: none;

    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0px;
        background: #fff;
        > a{
            font-weight: 450;
            display: block;
            color: #1d1f21;
            text-decoration: none;
        }


    }
    &__icon {
        display: none;
        justify-content: center;
        align-items: center;

        > .icon{
            width: 14px;
            height: 14px;
        }
        @include mq($screen-m) {
            display: flex;
        }
    }


    @include mq($screen-s) {
        grid-template-columns: $catalog-grid-thumbnail__template-columns\@small;
    }

    @include mq($screen-m) {
        grid-template-columns: $catalog-grid-thumbnail__template-columns\@medium;
    }

    @include isIE() {
        display: flex;
        flex-wrap: wrap;
    }
}
