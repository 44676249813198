@import 'filters-variables';

.filters {
    &__item {
        margin: $filters__item-margin;

        @include mq($screen-l) {
            margin: $filters__item-margin\@large;
        }
    }

    &__container {
        .modal & {
            @include mq($screen-l) {
                height: auto;
                animation: none;
                padding: $filters__container-padding\@large;
                max-height: 100%;
            }
        }
    }

    &__modal {
        @include mq($screen-l) {
            display: block;
            position: static;
            visibility: visible;
            opacity: 1;
            height: auto;
            padding: 0;
            background-color: $white;
        }
    }

    &__content {
        position: relative;

        @include mq($screen-m) {
            width: $filters__content-width\@medium;
            margin: $filters__content-margin\@medium;
        }

        @include mq($screen-l) {
            width: $filters__content-width\@large;
            margin: $filters__content-margin\@large;
        }
    }

    #filters-option-title{
        @include mq($screen-m) {
            display: none;
        }
    }

    &__trigger {
        width: 100%;
        justify-content: space-between;
        padding: $filters__trigger-padding;
        border: $filters__trigger-border;
        border-width: $filters__trigger-border-width;
        background-color: $filters__trigger-bg-color;

        @include mq($screen-m) {
            padding: $filters__trigger-padding\@medium;
        }

        @include mq($screen-l) {
            display: none;
        }

        > * {
            pointer-events: none;
        }

        .icon {
            width: $filters__trigger-icon-size--open;
            height: $filters__trigger-icon-size--open;
            fill: $filters__trigger-icon-fill--open;
        }

        .button__text {
            color: $filters__trigger-text-color;
            padding: $filters__trigger-text-padding;
            text-transform: $filters__trigger-text-transform;
            font-weight: $filters__trigger-font-weight;
        }
    }

    &__heading,
    &__trigger {
        font-family: $filters__heading-font-family;
        font-size: $filters__heading-font-size;
        font-weight: $filters__heading-font-weight;
        margin: $filters__heading-margin;

        @include mq($screen-m) {
            font-size: $filters__heading-font-size\@medium;
        }

        @include mq($screen-l) {
            padding: $filters__heading-padding\@large;
        }
    }

    &__content-top {
        padding: $filters__modal-top-padding;

        @include mq($screen-l) {
            padding: $filters__modal-top-padding\@large;
            border-width: $filters__border-width\@large;
        }
    }

    &__content-middle {
        padding: $filters__modal-middle-padding;
        @include mq($screen-l) {
            padding: $filters__modal-middle-padding\@large;
            overflow: initial;
        }
    }

    &__close-button {
        @include mq($screen-l) {
            display: none;
        }
    }
}
