@import 'collection-variables';

.collection-list {
    display: grid;
    grid-template-columns: $collection-list__template-columns;
    grid-gap: $collection-list__gap;
    margin: $collection-list__margin;
    padding: $collection-list__padding;
    list-style: none;

    .collection{
        position: relative;
    }
    .collection-item-details {
        position: absolute;
        top: 80%;
        text-align: center;
        width: 100%;
        padding: 10px 0;
        font-size: 1.3em;
        font-weight: 600;
        background: rgb(2,0,36);
        background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.7) 30%, rgba(255,255,255,0.7) 70%, rgba(0,212,255,0) 100%);

        a{
            text-decoration: none;
        }
    }

    @include mq($screen-s) {
        grid-template-columns: $collection-list__template-columns\@small;
    }

    @include mq($screen-m) {
        grid-template-columns: $collection-list__template-columns\@medium;
    }

    @include isIE() {
        display: flex;
        flex-wrap: wrap;
    }
}
