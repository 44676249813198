$categories-list__max-height           : 480px !default;
$categories-list__overflow             : hidden !default;

$categories-list__font-size            : $font-size-base !default;
$categories-list__font-weight          : $font-weight-base !default;
$categories-list__font-weight-hover    : $font-weight-bold !default;
$categories-list__text-transform       : none;
$categories-list__text-decoration      : none !default;
$categories-list__text-decoration-hover: none !default;
$categories-list__color                : $color-primary !default;
$categories-list__color-hover          : $color-primary !default;
$categories-list__bg-color-hover       : $gray-light !default;
$categories-list__item-padding         : 0 !default;
$categories-list__link-padding         : 8px $spacer--extra-small !default;

.categories-list {
    @include mq($screen-l) {
        max-height: $categories-list__max-height;
        overflow-x: $categories-list__overflow;
    }

    &__item {
        padding: $categories-list__item-padding;
    }

    &__link {
        display: block;
        padding: $categories-list__link-padding;
        font-size: $categories-list__font-size;
        font-weight: $categories-list__font-weight;
        color: $categories-list__color;
        text-transform: $categories-list__text-transform;
        text-decoration: $categories-list__text-decoration;

        &:hover,
        &[aria-current="true"] {
            background: $categories-list__bg-color-hover;
            color: $categories-list__color-hover;
            font-weight: $categories-list__font-weight-hover;
            text-decoration: $categories-list__text-decoration-hover;
        }
    }
}
