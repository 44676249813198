$filter__padding\@xl                        : 0 $spacer--medium !default;
$filter__transition                         : $transition-base !default;
$filter__heading-font-family                : $font-family-base !default;
$filter__heading-font-size                  : $font-size-large !default;
$filter__heading-text-transform             : none !default;
$filter__heading-margin                     : 0 !default;
$filter__content-padding                    : 0 0 $spacer--medium !default;
$filter__content-padding\@medium            : 0 0 $spacer--medium $spacer--medium !default;
$filter__content-padding\@large             : 0 0 $spacer--medium 0 !default;
$filter__content-overflow-y                 : auto !default;
$filter__label-wrapper-padding\@medium      : 0 0 0 $spacer--medium !default;
$filter__label-wrapper-padding\@large       : 0 !default;

// checkbox
$filter__content-max-height--checkbox       : 100% !default;
$filter__label-font-size--checkbox          : $font-size-medium !default;
$filter__label-text-transform--checkbox     : uppercase !default;
$filter__margin--checkbox-link              : 0 0 0 $spacer--extra-small !default;
$filter__margin--checkbox-link\@medium      : 0 !default;
$filter__label-font-wieght-checked--checkbox: $font-weight-bold !default;

// Swatch
$filter__swatch-size\@large                 : 25% !default;
$filter__swatch-margin                      : 0 $spacer $spacer--medium 0 !default;
$filter__swatch-margin\@large               : 0 0 $spacer--medium 0 !default;
$filter__swatch-option-selected-border-color: $color-primary !default;
$filter__swatch-option-size                 : 48px !default;
$filter__swatch-option-background           : $white !default;
$filter__swatch-option-color                : $color-primary !default;
$filter__swatch-option-border               : $border-base !default;
$filter__swatch-option-border-color-hover   : $color-primary !default;
$filter__swatch-option-border-color--active : $color-primary !default;

// Color
$filter__color-padding                      : 0 !default;
$filter__color-size\@large                  : auto !default;
$filter__color-option-width                 : 48px !default;
$filter__color-option-height                : auto !default;
$filter__color-option-margin                : 0 $spacer $spacer 0 !default;
$filter__color-option-border                : $border-base !default;
$filter__color-option-after-top             : 0 !default;
$filter__color-option-after-left            : 0 !default;
$filter__color-option-after-size            : 48px !default;
$filter__color-option-after-border-radius   : 0 !default;
$filter__color-option-after-border-color    : $color-primary !default;
$filter__color-sample-size                  : 32px !default;
$filter__color-sample-margin                : $spacer !default;
$filter__color-sample-border                : $border-base !default;
$filter__color-sample-border-radius         : 0 !default;
$filter__color-label-font-size              : $font-size-small !default;
$filter__color-label-color                  : $gray-darkest !default;

// category variant
$filter__padding--category\@large           : 0 !default;
$filter__border--category\@large            : $border-base !default;
$filter__border-width--category\@large      : 0 0 $border-width-base 0 !default;
